import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loader } from '../components/Loader/Loader'
import { TSUtils } from '../utils/TSUtils'
import EULAModal from '../components/modals/EULAModal'
import {
  async_fetch_skin,
  async_ml_draw,
  setShowMLOptinModal,
  setSponsorshipIdStep2,
} from '../redux/actions/lottery'
import LotteryConfigurationResponse from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import MarketingModal from '../components/modals/MarketingModal'
import HelpModal from '../components/modals/HelpModal'
import WheelApp from './00_Wheel/WheelApp'
import Redeem from './00_Wheel/08_Redeem/Redeem'
import {
  async_initApp,
  hideCookiesPolicyModal,
  hidePrivacyPolicyModal,
  setFingerprintBlocked,
  setLanguage,
  setMarketingModalState,
  showMenu,
  showPrivacyPolicyModal,
  trackVenueLoaded,
} from '../redux/actions/app'
import { tt } from '../i18n'
import WheelError from './02_Error/Error'
import Lost from './00_Wheel/14_Lost/Lost'
import i18n from 'i18next'
import Disabled from '../components/Disabled'
import WheelAnalyticsManager from '../analytics/WheelAnalyticsManager'
import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import { classNameForRatio } from '../utils/aspect_ratio'
import TSConstants, {
  FOOTER_HEIGHT,
  FRAUD_ALERT_HEIGHT,
} from '../utils/TSConstants'
import OfferToFriend from './04_OfferToFriend/OfferToFriend'
import RedeemByQRCode from './15_RedeemByQRCode/RedeemByQRCode'
import { renderLogo } from './helpers/renderLogo'
import { youNeedASmartphone } from './helpers/youNeedASmartphone'
import shouldDisplayLogo from './helpers/shouldDisplayLogo'
import renderCustomCss from './helpers/renderCustomCss'
import MonthlyLotteryOptinModal from './14_MonthlyLotteryIntro/MonthlyLotteryOptinModal'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import { configSelector } from '../redux/selectors/config'
import {
  displayIndividualContactFormSelector,
  displayMarketingModalSelector,
  displayProfessionalContactFormSelector,
  fingerprintBlockedSelector,
  fingerprintSelector,
  initFinishedSelector,
  showFooterSelector,
} from '../redux/selectors/app'
import {
  isBeforeWheelSelector,
  isDemoSelector,
  loaderStepSelector,
  loadingSelector,
  lotteryConfigSelector,
  lotteryGameConsumeSelector,
  lotteryStepSelector,
  showMLOptinModalSelector,
  skinSelector,
  venueSelector,
} from '../redux/selectors/lottery'
import Footer from '../components/Footer'
import useModalControls from '../hooks/useModalControls'
import LotteryType from '@tootsweet/model/lottery/LotteryType'
import SkillApp from './19_Skill/SkillApp'
import { RootState } from '../redux/combinedReducers'
import Menu from './21_Menu/Menu'
import PrivacyPolicyModal from '../components/modals/PrivacyPolicyModal'
import Cookies from '../components/cookies/Cookies'
import LegalMentionsModal from '../components/modals/LegalMentionsModal'
import CookiesPolicyModal from '../components/modals/CookiesPolicyModal'
import { renderBrowserNotSupported } from './helpers/renderBrowserNotSupported'
import FacebookPixel from '../analytics/FacebookPixel'
import moment from 'moment/moment'

const isIFrame = window !== window.parent
// console.log('isIframe', isIFrame)

interface Props {
  venueId?: string | undefined | null
  eventId?: string | undefined | null
  userToken: string | null
  validateGameId?: string | undefined | null
  displayEULA: boolean
  displayPP: boolean
  qrcode: boolean
  /**
   * Used to display the sponsorship welcome screen
   */
  sponsorshipIdStep1?: string | null
  /**
   * Sent with the /play route to mark the sponsorship as used
   */
  sponsorshipIdStep2?: string | null

  isOfferGift: boolean
  offeredGiftLabel?: string | null
  lng?: string | null

  redeemGameId?: string | null
  draftID?: string | null
}

const App: FunctionComponent<Props> = (props) => {
  const { validateGameId, redeemGameId } = props

  const [didDrawML, setDidDrawML] = React.useState(false)
  const isMobile = useMemo(() => TSUtils.isMobile(), [])
  const isTablet = useMemo(() => TSUtils.isTablet(), [])
  const isIOS = useMemo(() => TSUtils.isIOS(), [])
  // const isFBBrowser = useMemo(() => TSUtils.isFacebookBrowser(), [])
  const isFirefoxBrowser = useMemo(() => TSUtils.isFirefoxBrowser(), [])
  const isBraveBrowser = useMemo(() => TSUtils.isBraveBrowser(), [])
  const isInstagramBrowser = useMemo(() => TSUtils.isInstagramBrowser(), [])

  const mustRedeem = useRef(
    !!validateGameId && (validateGameId?.length ?? 0) > 0 && !props.isOfferGift
  )
  const mustRedeemByQRCode = useRef(
    (props.redeemGameId?.length ?? 0) > 0 && !props.isOfferGift
  )

  const gameContainerRef = React.createRef<HTMLDivElement>()
  const [gameContainerWidth, setGameContainerWidth] = useState(0)

  const [isSkillGameWithPlaceSelector, setIsSkillGameWithPlaceSelector] =
    useState(false)

  const dispatch = useDispatch()

  const siteConfig = useSelector(configSelector)
  const venue = useSelector(venueSelector)
  const lotteryConfig = useSelector(lotteryConfigSelector)
  const showFooter = useSelector(showFooterSelector)
  const step = useSelector(lotteryStepSelector)
  const loading = useSelector(loadingSelector)
  const loaderStep = useSelector(loaderStepSelector)
  const lotteryGameConsume = useSelector(lotteryGameConsumeSelector)
  const isBeforeWheel = useSelector(isBeforeWheelSelector)
  const isDemo = useSelector(isDemoSelector)
  const displayMarketingModal = useSelector(displayMarketingModalSelector)
  const displayProfessionalContactForm = useSelector(
    displayProfessionalContactFormSelector
  )
  const displayIndividualContactForm = useSelector(
    displayIndividualContactFormSelector
  )
  const fingerprint = useSelector(fingerprintSelector)
  const fingerprintBlocked = useSelector(fingerprintBlockedSelector)
  const showMLOptinModal = useSelector(showMLOptinModalSelector)
  const skin = useSelector(skinSelector)
  const skinId = useSelector((s: RootState) => s.lottery.skinId)

  const helpModalControls = useModalControls({})
  const eulaModalControls = useModalControls({
    initialValue: false,
  })
  const legalMentionsModalControls = useModalControls({
    initialValue: false,
  })
  const showPPModal = useSelector((state: RootState) => state.app.showPPModal)
  const showCPModal = useSelector((state: RootState) => state.app.showCPModal)

  const initFinished = useSelector(initFinishedSelector)

  const tryLockScreen = async () => {
    try {
      if (window?.screen?.orientation) {
        await window?.screen?.orientation?.lock('portrait')
      }
    } catch (err) {}
  }

  const closeMarketingModal = () => {
    dispatch(setMarketingModalState(false))
  }

  const shouldDisplayWheel = (
    lotteryConfig: LotteryConfigurationResponse | undefined,
    step: LotteryV2Step | null
  ) => {
    if (step?.stepType === LotteryV2StepType.SearchBar) {
      return true
    }

    if (!lotteryConfig) {
      return false
    }

    if (!lotteryConfig.type) {
      return false
    }

    return lotteryConfig.type === LotteryType.Wheel
  }

  useEffect(() => {
    if (
      !gameContainerRef ||
      !gameContainerRef.current ||
      !gameContainerRef.current?.clientWidth ||
      gameContainerRef.current?.clientWidth === gameContainerWidth
    ) {
      return
    }
    setGameContainerWidth(gameContainerRef.current?.clientWidth)
  }, [gameContainerRef])

  useEffect(() => {
    if (props.displayPP) {
      dispatch(showPrivacyPolicyModal())
    }
  }, [props.displayPP])

  useEffect(() => {
    tryLockScreen()
  }, [])

  useEffect(() => {
    const htmls = document.getElementsByTagName('html')
    if (htmls && htmls.length > 0) {
      const clazz = classNameForRatio()
      if (clazz) {
        htmls[0].classList.add(clazz)
      }
    }
    dispatch(
      async_initApp(
        props.userToken,
        siteConfig,
        validateGameId,
        redeemGameId,
        props.venueId,
        props.draftID
      )
    )

    if (props.sponsorshipIdStep2) {
      dispatch(setSponsorshipIdStep2(props.sponsorshipIdStep2))
    }
  }, [])

  useEffect(() => {
    if (lotteryConfig?.hasML && !didDrawML) {
      setDidDrawML(true)
      dispatch(async_ml_draw())
    }
  }, [lotteryConfig?.hasML])

  useEffect(() => {
    if (!venue || !fingerprint) {
      return
    }
    dispatch(trackVenueLoaded())
  }, [fingerprint, venue])

  useEffect(() => {
    /**
     * The key is the concatenation of all the steps separated by semicolons.
     * It is used to differentiate different user journeys.
     */
    if (
      lotteryConfig &&
      lotteryConfig.steps &&
      lotteryConfig.steps.length > 0
    ) {
      const steps = lotteryConfig.steps.map(
        (step: LotteryV2Step) => step.stepType
      )
      const key = steps.join(';')
      WheelAnalyticsManager.setKey(key)

      const mustRedeem =
        props.validateGameId &&
        props.validateGameId.length > 0 &&
        !props.isOfferGift
      const mustRedeemByQRCode =
        props.redeemGameId &&
        props.redeemGameId.length > 0 &&
        !props.isOfferGift

      if (!mustRedeem && !mustRedeemByQRCode) {
        WheelAnalyticsManager.gameLoaded()
      }
    }

    if (lotteryConfig?.customizeColors) {
      document
        .querySelector('meta[name="theme-color"]')
        ?.setAttribute('content', lotteryConfig.colFooterBack ?? '')
      document
        .querySelector('meta[name="msapplication-TileColor"]')
        ?.setAttribute('content', lotteryConfig.colFooterBack ?? '')
    }
  }, [lotteryConfig])

  useEffect(() => {
    if (!props.lng) {
      return
    }
    const lang = props.lng
    i18n.changeLanguage(lang)
    dispatch(setLanguage(lang))
  }, [])

  useEffect(() => {
    if (!lotteryConfig) {
      return
    }
    if (!skin) {
      const gameStep = lotteryConfig.steps?.find((s: LotteryV2Step) =>
        TSUtils.isGameStep(s)
      )
      dispatch(async_fetch_skin(gameStep?.skin))
    }

    setIsSkillGameWithPlaceSelector(
      (lotteryConfig?.type === LotteryType.Catcher ||
        lotteryConfig?.type === LotteryType.Quizz) &&
        lotteryConfig.steps?.[0].stepType === LotteryV2StepType.PlaceSelection
    )
  }, [lotteryConfig])

  useEffect(() => {
    if (props.displayEULA) {
      dispatch(showMenu())
    }
  }, [props.displayEULA])

  const isTooSoon = useMemo(() => {
    return (
      !mustRedeem.current &&
      !!lotteryConfig &&
      !!lotteryConfig.minTs &&
      TSUtils.getNowTimestamp() < lotteryConfig.minTs
    )
  }, [lotteryConfig, mustRedeem])

  const isTooLate = useMemo(() => {
    return (
      !mustRedeem.current &&
      !!lotteryConfig &&
      !!lotteryConfig.maxTs &&
      TSUtils.getNowTimestamp() > lotteryConfig.maxTs
    )
  }, [lotteryConfig, mustRedeem])

  const renderGame = (isDisabled: boolean) => {
    const { qrcode, sponsorshipIdStep1 } = props

    const displayChristmasScratchCard =
      (!mustRedeem.current &&
        lotteryConfig &&
        lotteryConfig.type === LotteryType.ScratchCardChristmas) ||
      false
    const displayAdventCalendar =
      (!mustRedeem.current &&
        lotteryConfig &&
        lotteryConfig.type === LotteryType.AdventCalendar) ||
      false
    const displayScratchCard =
      (!mustRedeem.current &&
        lotteryConfig &&
        lotteryConfig.type === LotteryType.ScratchCard) ||
      false
    const displaySlotMachine =
      (!mustRedeem.current &&
        lotteryConfig &&
        lotteryConfig.type === LotteryType.SlotMachine) ||
      false
    const displayGiftMachine =
      (!mustRedeem.current &&
        lotteryConfig &&
        lotteryConfig.type === LotteryType.GiftMachine) ||
      false
    const displayGameFakir =
      (!mustRedeem.current &&
        lotteryConfig &&
        lotteryConfig.type === LotteryType.Fakir) ||
      false
    const displayMysteryBoxes =
      (!mustRedeem.current &&
        lotteryConfig &&
        lotteryConfig.type === LotteryType.MysteryBoxes) ||
      false
    const displayDice =
      (!mustRedeem.current &&
        lotteryConfig &&
        lotteryConfig.type === LotteryType.Dice) ||
      false

    const displayWheel =
      isSkillGameWithPlaceSelector ||
      (!mustRedeem.current &&
        !mustRedeemByQRCode.current &&
        (shouldDisplayWheel(lotteryConfig, step) ||
          displayChristmasScratchCard ||
          displayAdventCalendar ||
          displayScratchCard ||
          displaySlotMachine ||
          displayGiftMachine ||
          displayGameFakir ||
          displayMysteryBoxes ||
          displayDice))

    if (!mustRedeem.current && !mustRedeemByQRCode.current && isDisabled) {
      if (!!lotteryConfig?.disabledRedirURL) {
        return null
      }
      return (
        <Disabled
          customTitle={lotteryConfig?.customSuspensionTitle}
          customText={lotteryConfig?.customSuspensionText}
        />
      )
    }

    return (
      <>
        {props.isOfferGift && (
          <OfferToFriend offeredGiftLabel={props.offeredGiftLabel} />
        )}

        {displayWheel && (
          <WheelApp
            validateGameId={validateGameId}
            isIOS={isIOS}
            qrcode={qrcode}
            sponsorshipIdStep1={sponsorshipIdStep1}
            venueProp={venue}
            isOfferGift={props.isOfferGift}
            offeredGiftLabel={props.offeredGiftLabel}
          />
        )}

        {!displayWheel &&
          !mustRedeem.current &&
          !props.isOfferGift &&
          !sponsorshipIdStep1 &&
          lotteryConfig?.type === LotteryType.Quizz && (
            <SkillApp
              finalPlaceName={venue?.name ?? ''}
              isIOS={isIOS}
              gameContainerHeight={gameContainerHeight}
              gameContainerWidth={gameContainerWidth}
            />
          )}

        {!displayWheel &&
          !mustRedeem.current &&
          !props.isOfferGift &&
          !sponsorshipIdStep1 &&
          lotteryConfig?.type === LotteryType.Catcher && (
            <SkillApp
              finalPlaceName={venue?.name ?? ''}
              isIOS={isIOS}
              gameContainerHeight={gameContainerHeight}
              gameContainerWidth={gameContainerWidth}
            />
          )}

        {mustRedeem.current && validateGameId && (
          <Redeem
            validateGameId={validateGameId}
            isSpontaneous={true}
            isIOS={isIOS}
            qrcode={props.qrcode}
          />
        )}

        {mustRedeemByQRCode.current && props.redeemGameId && (
          <RedeemByQRCode gameId={props.redeemGameId} />
        )}
      </>
    )
  }

  const hasFraudAlert =
    lotteryGameConsume?.nPreviousGames7Days &&
    lotteryGameConsume.nPreviousGames7Days > 2

  const displayFraudAlert = !!(hasFraudAlert && mustRedeem.current)
  const gameContainerHeight =
    window.innerHeight -
    (displayFraudAlert && (isMobile || isTablet) ? FRAUD_ALERT_HEIGHT : 0) -
    (showFooter ? FOOTER_HEIGHT : 0)

  const hasError = step?.stepType === LotteryV2StepType.Error
  const hasLost = step?.stepType === LotteryV2StepType.Lost
  let hasNotch =
    lotteryConfig?.type === LotteryType.Fakir ||
    lotteryConfig?.type === LotteryType.MysteryBoxes ||
    lotteryConfig?.type === LotteryType.AdventCalendar ||
    lotteryConfig?.type === LotteryType.Dice

  if (!skin?.hasNotch) {
    hasNotch = false
  }

  const hasBG_URL =
    !!lotteryConfig?.bgURL && (!mustRedeem.current || !skin?.hideBGInCoupon)
  const isDisabled = lotteryConfig?.isDisabled ?? false

  const hasCalendlyEmbed = mustRedeem.current && !!lotteryConfig?.calendlyLink

  const displayLogo = shouldDisplayLogo(
    step,
    mustRedeem.current,
    mustRedeemByQRCode.current,
    lotteryConfig,
    isDisabled,
    props.isOfferGift,
    isBeforeWheel,
    isSkillGameWithPlaceSelector
  )

  if (
    !isIFrame &&
    !isMobile &&
    !isDemo &&
    !lotteryConfig?.desktop &&
    !mustRedeem.current
  ) {
    return (
      <>
        {renderCustomCss(lotteryConfig, skinId, skin)}
        {youNeedASmartphone(
          hasBG_URL,
          displayFraudAlert,
          gameContainerHeight + FOOTER_HEIGHT,
          lotteryConfig,
          skin,
          hasNotch
        )}
      </>
    )
  }

  if (fingerprintBlocked) {
    return (
      <>
        {renderCustomCss(lotteryConfig, skinId, skin)}
        {renderBrowserNotSupported(
          gameContainerHeight,
          hasBG_URL,
          lotteryConfig
        )}
      </>
    )
  }

  if (isInstagramBrowser || isFirefoxBrowser || isBraveBrowser) {
    return (
      <>
        {renderCustomCss(lotteryConfig, skinId, skin)}
        {renderBrowserNotSupported(
          gameContainerHeight,
          hasBG_URL,
          lotteryConfig
        )}
      </>
    )
  }

  if (!initFinished) {
    return (
      <Loader
        loading={true}
        displayText={true}
        text={tt('loading')}
        isDark={true}
        isFixed={true}
      />
    )
  }

  if (isTooSoon) {
    return (
      <>
        {renderCustomCss(lotteryConfig, skinId, skin)}
        {hasBG_URL && (
          <div className="game-container">
            <img
              className="twic"
              src={TSConstants.PLACEHOLDER_URL}
              data-src={`image:/cdm/custom_bgs/${lotteryConfig?.bgURL}`}
              data-src-transform="cover=WxH"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: '100%',
                height: gameContainerHeight,
              }}
            />
          </div>
        )}
        <div
          className={`game-container ${hasBG_URL ? 'no-bg' : ''}`}
          style={{ zIndex: 1 }}
        >
          {displayLogo &&
            renderLogo(
              lotteryConfig,
              hasBG_URL,
              step ?? undefined,
              hasError,
              hasLost,
              hasNotch,
              isSkillGameWithPlaceSelector
            )}
          <Disabled
            customTitle={tt('too_soon_title')}
            customText={tt('too_soon_text', {
              formattedDateAndTime: moment(lotteryConfig?.minTs, 'X').format(
                tt('date_format_date_hour')
              ),
            })}
          />
        </div>
      </>
    )
  }

  if (isTooLate) {
    return (
      <>
        {renderCustomCss(lotteryConfig, skinId, skin)}
        {hasBG_URL && (
          <div className="game-container">
            <img
              className="twic"
              src={TSConstants.PLACEHOLDER_URL}
              data-src={`image:/cdm/custom_bgs/${lotteryConfig?.bgURL}`}
              data-src-transform="cover=WxH"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: '100%',
                height: gameContainerHeight,
              }}
            />
          </div>
        )}
        <div
          className={`game-container ${hasBG_URL ? 'no-bg' : ''}`}
          style={{ zIndex: 1 }}
        >
          {displayLogo &&
            renderLogo(
              lotteryConfig,
              hasBG_URL,
              step ?? undefined,
              hasError,
              hasLost,
              hasNotch,
              isSkillGameWithPlaceSelector
            )}
          <Disabled
            customTitle={tt('too_late_title')}
            customText={tt('too_late_text', {
              formattedDateAndTime: moment(lotteryConfig?.maxTs, 'X').format(
                tt('date_format_date_hour')
              ),
            })}
          />
        </div>
      </>
    )
  }

  return (
    <>
      {renderCustomCss(lotteryConfig, skinId, skin)}

      {displayFraudAlert && (
        <div className="cm-fraud-alert cm-pad" style={{ marginBottom: 0 }}>
          {tt('tries_during_last_7_days', {
            nPreviousGames7Days: lotteryGameConsume?.nPreviousGames7Days,
          })}
        </div>
      )}

      {hasBG_URL && (
        <div className="game-container">
          <img
            className="twic"
            src={TSConstants.PLACEHOLDER_URL}
            data-src={`image:/cdm/custom_bgs/${lotteryConfig?.bgURL}`}
            data-src-transform="cover=WxH"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: '100%',
              height: gameContainerHeight,
            }}
          />
        </div>
      )}

      <div
        ref={gameContainerRef}
        className={`game-container ${hasBG_URL ? 'no-bg' : ''} ${
          mustRedeem.current ? 'redeem' : ''
        }`}
        style={{
          height: hasCalendlyEmbed ? undefined : gameContainerHeight,
          overflowY: hasCalendlyEmbed ? 'scroll' : undefined,
        }}
      >
        {displayLogo &&
          renderLogo(
            lotteryConfig,
            hasBG_URL,
            step ?? undefined,
            hasError,
            hasLost,
            hasNotch,
            isSkillGameWithPlaceSelector
          )}

        {hasError && (
          <div
            className={`cm-pad ${
              lotteryConfig?.disableCentering ? '' : 'text-center'
            }`}
          >
            <WheelError />
          </div>
        )}
        {hasLost && (
          <div
            className={`cm-pad ${
              lotteryConfig?.disableCentering ? '' : 'text-center'
            }`}
          >
            <Lost />
          </div>
        )}
        {!hasError && !hasLost && renderGame(isDisabled)}
      </div>

      <Footer handleHelpModalOpen={helpModalControls.openModal} />

      <EULAModal
        show={eulaModalControls.isOpen}
        closeModal={eulaModalControls.closeModal}
        customEULA={lotteryConfig?.rulesURL}
        companyName={lotteryConfig?.companyName}
      />
      <PrivacyPolicyModal
        show={showPPModal}
        closeModal={() => dispatch(hidePrivacyPolicyModal())}
        customPrivacyPolicy={lotteryConfig?.ppURL}
        companyName={lotteryConfig?.companyName}
        gdprEmail={lotteryConfig?.gdprEmail || 'clients@kadow.club'}
      />
      <CookiesPolicyModal
        show={showCPModal}
        closeModal={() => dispatch(hideCookiesPolicyModal())}
        customCookiesPolicy={lotteryConfig?.pcURL}
        companyName={lotteryConfig?.companyName}
        gdprEmail={lotteryConfig?.gdprEmail || 'clients@kadow.club'}
      />
      <LegalMentionsModal
        show={legalMentionsModalControls.isOpen}
        closeModal={legalMentionsModalControls.closeModal}
      />
      <MarketingModal
        show={displayMarketingModal}
        displayProfessionalContactForm={displayProfessionalContactForm}
        displayIndividualContactForm={displayIndividualContactForm}
        onHide={closeMarketingModal}
        isIOS={isIOS}
        fp={fingerprint}
      />
      <HelpModal
        show={helpModalControls.isOpen}
        onHide={helpModalControls.closeModal}
        isIOS={isIOS}
        displayContactFormOnHelp={lotteryConfig?.displayContactFormOnHelp}
      />
      <MonthlyLotteryOptinModal
        show={showMLOptinModal}
        closeModal={() => dispatch(setShowMLOptinModal(false))}
      />
      <Loader
        loading={loading}
        loaderStep={loaderStep}
        displayText={false}
        isFixed={true}
      />
      <Menu
        eulaModalControls={eulaModalControls}
        legalMentionsModalControls={legalMentionsModalControls}
      />
      <Cookies />
      {(!!step?.stepType || mustRedeem.current) && !!lotteryConfig?.fbp && (
        <FacebookPixel
          pixelId={lotteryConfig.fbp}
          lead={mustRedeem.current}
          route={
            mustRedeem.current
              ? LotteryV2StepType.Redeem
              : step?.stepType || LotteryV2StepType.Unknown
          }
        />
      )}
    </>
  )
}

export default App
