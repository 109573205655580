enum LotteryV2StepType {
  Wheel = "Wheel",
  Email = "Email",
  Phone = "Phone",
  Photo = "Photo",
  Facebook = "Facebook",
  Google = "Google",
  Snapchat = "Snapchat",
  Instagram = "Instagram",
  TwoSocialNetworks = "TwoSocialNetworks",
  DownloadApp = "DownloadApp",
  Empty = "Empty",
  Deliveroo = "Deliveroo",
  UberEats = "UberEats",
  DeliverooUberEats = "DeliverooUberEats",
  TripAdvisor = "TripAdvisor",
  McDoPlus = "McDoPlus",
  Sponsorship = "Sponsorship",
  SocialPhoto = "SocialPhoto",
  CDiscount = "CDiscount",
  Fnac = "Fnac",
  Prospection = "Prospection",
  PlaceSelection = "PlaceSelection",
  ScratchCardChristmas = "ScratchCardChristmas",
  ScratchCard = "ScratchCard",
  AdventCalendar = "AdventCalendar",
  CustomLink = "CustomLink",
  MonLogis = "MonLogis",
  TikTok = "TikTok",
  EatalyPoll = "EatalyPoll",
  SlotMachine = "SlotMachine",
  LPSponsorship = "LPSponsorship",
  Poll = "Poll",
  GenderAndAge = "GenderAndAge",
  CustomForm = "CustomForm",
  ML_Intro = "ml_intro",
  ML_Game = "ml_game",
  Quizz = "Quizz",
  Staffing = "Staffing",
  GiftMachine = "GiftMachine",
  Random = "Random",
  Random3 = "Random3",
  Unknown = "Unknown",
  SearchBar = "SearchBar",
  Info = "Info",
  Form = "Form",
  Play = "Play",
  Redeem = "Redeem",
  Error = "Error",
  Lost = "Lost",
  Offer = "Offer",
  MLEnd = "MLEnd",
  Catcher = "Catcher",
  Fakir = "Fakir",
  Dice = "Dice",
  MysteryBoxes = "MysteryBoxes",
  UTMRedirection = "UTMRedirection",
  ThreeSocialNetworks = "ThreeSocialNetworks",
}

export default LotteryV2StepType;
